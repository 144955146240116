<template>
    <div class="d-block d-lg-none">
        <div class="row">
            <div class=" col-10 mb-0 col-sm-9 col-lg-10">
                <p class="aboutMeMobile" style="margin-left: 20px;">About Me</p>
            </div>
            <div class="col-2 col-sm-3 col-lg-2">
                <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
            </div>
        </div>
        <div class="hr-border"></div>
    </div>
    <div class="mobileStyleDoctor">
        <div class="row">
            <div class="col-12 proflemobile">
                <input type="file" style="display:none" id="doctor_img" name="doctor_img" />
                <img src="~@/assets/img/images/doctor.png" style="width:100px;height:100px">
                <!-- <div class="my-2">
                    <button class="btn text-white" style="background:#34989f;">Upload Photo</button>
                </div> -->
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-sm-6 my-2">
                <label>First name <span class="star">*</span> </label>
                <input type="text" id="firstName" name="firstName" :disabled="!!doctorFirstname" v-model="doctorFirstname" 
                    autocomplete="off" class="form-control cursor UpdateTextAlignment" />
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-sm-6 my-2">
                <label>Last name <span class="star">*</span> </label>
                <input type="text" id="lastName" name="lastName" :disabled="notshow" v-model="doctorLastName"
                    autocomplete="off" class="form-control cursor UpdateTextAlignment" />
            </div>
        </div>
        <div class="row ">
            <div class="col-12 col-md-6 col-sm-6 col-lg-6 my-2">
                <label>Age <span class="star">*</span></label>
                <input type="number" class="form-control UpdateTextAlignment" 
       v-model="doctorAge"
       v-bind:class="[!doctorAge || doctorAge >= 22 ? '' : 'redborder', enableAllFields === true ? 'hide-cursor' : '']"
       :disabled="enableAllFields"
       @input="doctorAge = doctorAge.toString().slice(0, 3)"
       min="22">


                <h6 class="mt-1">Note: Minimum 22 years</h6>

            </div>

            <div class="col-12 col-md-6 col-sm-6 col-lg-6 my-2">
                <label>Gender<span class="star">*</span></label>
                <select class="form-control text-start" name="gender" id="gender" v-model="doctorGender" 
                v-bind:class="[enableAllFields === true ? 'hide-cursor' : '']" :disabled="enableAllFields"
                >
                 <option >   Male</option>
                 <option>  Female </option>
                <option>Others</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-sm-6 col-lg-6 my-2" style="margin-top:auto;">
                <label style="margin-bottom:5px;">Registration Number <span class="star">*</span></label>
                <input type="text" v-model="doctorRegNo"
                    v-bind:class="[enableAllFields === true ? 'hide-cursor' : '']" :disabled="enableAllFields"
                    id="registerNo" name="registerNo" class="form-control UpdateTextAlignment" />
            </div>
            <div class="col-12 col-md-6 col-sm-6 col-lg-6 my-2">
                <label>Educational qualification <span class="star">*</span> <span style="font-size:14px;color:#000">(Please
                        seperate
                        your degrees by a comma)</span></label>
                <input type="text" v-model="doctorEducation" id="educational" name="educational"
                    class="form-control UpdateTextAlignment" v-bind:class="[adminAction === true ? 'hide-cursor' : '']"
                    :disabled="enableAllFields" />
            </div>
        </div>
        <div class="row ">
            <div class="col-12 my-2">
                <div class="form-group">
                    <label>Any other information about you that would be useful to patients </label>
                    <textarea id="information" v-model="doctorInformation" name="information"
                        class="form-control UpdateTextAlignment" v-bind:class="[adminAction === true ? 'hide-cursor' : '']"
                        :disabled="enableAllFields"></textarea>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-lg-5 col-md-5 col-8 my-2">
                <div class="form-group">
                    <label>Mobile number<span class="star">*</span></label>
                    <input type="text" :disabled="notshow" v-model="doctorMobile" id="mobile" name="mobile"
                        class="form-control cursor UpdateTextAlignment" />
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-4 my-2" style="margin-top:15px">
                <button type="button" @click="addNumber = true" id="btn_phone_add" style="cursor: auto;  border-color: transparent;" class="btn btn_add"><img
                        src="@/assets/images/verify.png" style="width:50px;" /></button>
            </div>
            <!-- <div class="col-2 col-md-1" style="margin-top:15px">
            <button type="button" @click="addNumber = true" style="font-size:30px" id="btn_phone_add" class="btn btn_add">
                <font-awesome-icon :icon='["fas", "plus-circle"]' />
            </button>
        </div> -->
            <div class="col-lg-5 col-md-5 my-2">
                <div class="form-group primarystyle">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="primaryRadio" type="radio" name="primary_phone_check" 
                            id="primary_phone_check" :value="true" style="margin-left:5px;cursor: pointer;" />
                        <label class="form-check-label" for="primary_phone_check" style="margin-left:7px">Primary</label>
                    </div>
                </div>
            </div>
            <div class="form-group ">
                <div>
                    <label>Select a messaging method<span class="star">*</span></label>
                </div>
                <div class="form-check form-check-inline mobileDisplayCommunication" v-for="value in Communication"
                    :key="value">
                    <input class="form-check-input" type="radio" name="communication" :id="value.id" :value="value.id" style="cursor: pointer;"
                        v-model="doctorCommunicaton" 
                        v-bind:class="[enableAllFields === true ? 'hide-cursor' : '']" :disabled="enableAllFields">
                    <label class="form-check-label" for="communication">{{ value.communication }}</label>
                </div>
            </div>
        </div>

        <div class="row ">
            <div class="col-lg-5 col-md-5 col-8 my-2">
                <div class="form-group">
                    <label>Mail ID <span class="star">*</span></label>
                    <input type="email" @input="handleEmailInput"  v-bind:class="[enableAllFields === true ? 'hide-cursor' : '']"
                        v-model="doctorEmail"
                        class="form-control ban-cursor UpdateTextAlignment" :disabled="enableAllFields" />
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-4 my-2">
                <button type="button" class="btn btn-blue-color"  :disabled="true" @click="verifyEmail"
                    style="color: white ; background: #cccccc !important;margin-top:23px;pointer-events: none;" >Verify</button>
            </div>
            <div class="col-lg-5 col-md-5 my-2">
                <div class="form-group primarystyle">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="primary_email_check" id="primary_email_check"
                            :value="true" style="margin-left:5px;cursor: pointer;" v-model="primaryEmail">
                        <label class="form-check-label" for="primary_email_check" style="margin-left:7px">Primary</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row ">
            <div class="col-6 my-2" style="margin-top:auto;">
                <label>Pincode <span class="star">*</span>
                </label>
                <input type="text"  v-model="doctorPincode" id="pincode" name="pincode"
                v-bind:class="[enableAllFields === true ? 'hide-cursor' : '']" :disabled="!!doctorPincode" 

                    class="form-control  UpdateTextAlignment" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12">
                <h5>My practice areas <span class="star">*</span></h5>
            </div>
        </div>
        <!-- end heading -->
        <div class="row">
            <div class="col-12 col-sm-6 col-lg-4" v-for="(checkOption, index) in Option" :key="index">
                <label>
                    <input type="checkbox" v-model="checkOption.isActive" class="me-2 practiceBox" name="pracriceAreas" 
                        id="pracriceAreas" v-bind:class="[adminAction === true ? 'hide-cursor' : '']"
                        :disabled="adminAction || docData" :checked="checkOption.isActive" />
                    {{ checkOption.practiceArea }}
                </label>
            </div>
        </div>

        <div class="row" v-if="currentUserStatus?.status?.status != 'Approved'" style="padding-bottom: 15px;">
            <div class="col-12 col-md-6 col-lg-6 col-sm-6  mt-3">
                <button class="btn btn-blue-color text-white" :disabled="click" @click="onUpdate($event)"
                    style="width:100%">Update my RxIx
                    access</button>
            </div>
            <!-- <div class="col-12 col-md-6 col-lg-6 col-sm-6 mt-3">
                <a href="/doctor/index">
                    <button class="btn btn-color text-white" style="width:100%" :disabled="click">Later</button>
                </a>
            </div> -->
        </div>
        <div class="row" v-if="currentUserStatus?.status?.status == 'Approved' && ! this.docData" >
            <div class="col-12 col-md-6 col-lg-6 col-sm-6 mt-3">
                <button class="btn btn-blue-color text-white" :disabled="click" @click="ReUpdate()"
                    style="width:100%">Save</button> 
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
    props: [" practicebox(id)","docData"],
    data() {
        return {
            doctorFirstname:'',
            doctorLastName:'',
            doctorAge:'',
            doctorEmail:'',
            doctorEducation:'',
            doctorInformation:'',
            doctorRegNo:'',
            doctorGender:'',
            doctorMobile:'',
            doctorCommunicaton:'',
            doctorPincode: '',
            userPayload: {},
            save: false,
            addNumber: false,
            addEmail: false,
            Option: [],
            primaryRadio: false,
            myResult: [],
            click: false,
            notshow: true,
            enableAllFields: false,
            adminAction: false,
            primaryEmail: false,
            Communication: [],
            currentUserStatus: [],
            doctorPracticeArea: []
        }
    },
    created: function () {
        this.getUserDetail();
        this.getPracticeArea();
        this.currentRoute = this.$route.path.split('/')[2];
        this.tagId = this.$route.query.id;
        this.getStatus();
        this.getCommunication();

    },
    computed: {
        
        isEmailValid() {
            const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            return emailRegex.test(this.doctorEmail);
        },
    },
    methods: {
        handleEmailInput() {
      this.doctorEmail =  this.doctorEmail.toLowerCase();
    },
        GoSetting() {
            window.scrollTo(0, 0);
            this.$router.push(`/doctor/mobile-settings`);
        },
        async getStatus() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/hcps/${localStorage.getItem('id')}/one`)
                .then((response) => {
                    if (response?.data) {
                        this.currentUserStatus = response.data
                        if (this.currentUserStatus?.status?.status === 'Approved') {
                            this.enableAllFields = true
                            this.save = true
                        }
                       else if (this.currentUserStatus?.status?.status === 'Rejected') {
                            this.enableAllFields = false
                            this.save = false
                        }
                       else if (this.currentUserStatus?.status?.status === 'UnderReview') {
                            this.click = true
                            this.enableAllFields = true
                            this.adminAction = true
                        }
                    }
                })
        },
        async getPracticeArea() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            await axios
                .get(`${BASE_API_URL}/hcp-types?hcpTypes=doctor`)
                .then((response) => {
                    if (response?.data) {
                        response.data.forEach(item => {
                            item.selected = (item.id == this.tagId ? true : false)
                        });
                        response?.data.map((data) => {
                            this.myResult?.map((id) => {
                                if (data.id == id) {
                                    data.selected = true;
                                }
                            })
                            if (data.is_delete === false && data.check === true) {
                                this.Option.push(data);
                            }
                            this.getUserDetail()
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        async getUserDetail() {
            if(this.docData){
                this.doctorFirstname=this.docData.firstName
                        this.doctorLastName=this.docData.lastName
                        this.doctorAge=this.docData.age
                        this.doctorGender=this.docData.gender
                        this.doctorRegNo=this.docData.registerNo
                        this.doctorEducation=this.docData.educational
                        this.doctorInformation=this.docData.information
                        this.doctorMobile=this.docData.mobile
                        this.doctorEmail=this.docData.email
                        this.doctorPincode = this.docData?.pincode?.id
                        this.doctorCommunicaton = this.docData?.communication?.id
                        this.doctorPracticeArea = this.docData.practiceId
                        this.Option.forEach(item => {
                            const hasItem = this.doctorPracticeArea.some(doctor => doctor.id === item.id);
                            item.isActive = !!hasItem;
                        })
                        if (this.doctorMobile) {
                            this.primaryRadio = true
                            // this.primaryEmail = true
                            this.unshow = true
                        }
            }else{
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            await axios
                .get(`${BASE_API_URL}/hcps/${localStorage.getItem('id')}/one`)
                .then((response) => {
                    if (response?.data) {
                        this.userPayload = response.data;
                        this.doctorFirstname=this.userPayload.firstName
                        this.doctorLastName=this.userPayload.lastName
                        this.doctorAge=this.userPayload.age
                        this.doctorGender=this.userPayload.gender
                        this.doctorRegNo=this.userPayload.registerNo
                        this.doctorEducation=this.userPayload.educational
                        this.doctorInformation=this.userPayload.information
                        this.doctorMobile=this.userPayload.mobile
                        this.doctorEmail=this.userPayload.email
                        this.doctorPincode = this.userPayload?.pincode?.id
                        this.doctorCommunicaton = this.userPayload?.communication?.id
                        this.doctorPracticeArea = this.userPayload.practiceId
                        this.Option.forEach(item => {
                            const hasItem = this.doctorPracticeArea.some(doctor => doctor.id === item.id);
                            item.isActive = !!hasItem;
                        })
                        if (this.doctorMobile) {
                            this.primaryRadio = true
                            // this.primaryEmail = true
                            this.unshow = true
                        } }
                })
                .catch(function (error) {
                    console.log(error?.response);
                });
            }
        },
        async onUpdate() {
             const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            const selectedPracticeIds = [];
            this.Option.forEach(item => {
                if (item.isActive) {
                    selectedPracticeIds.push(item);
                }
            });
          
            if (!this.doctorFirstname) {
                this.$swal("Please enter the First Name!");
                return
            }
            if (!this.doctorLastName) {
                this.$swal("Please enter the Last Name!");
                return
            } if (!this.doctorAge) {
                this.$swal("Please enter the Age!");
                return
            } if (!this.doctorGender) {
                this.$swal("Please enter the Gender!");
                return
            }
            if (!this.doctorRegNo) {
                this.$swal("Please enter the register number!");
                return
            }
            if (!this.doctorEducation) {
                this.$swal("Please enter the education qualification!");
                return
            }
            if (!this.doctorCommunicaton) {
                this.$swal("Please select a messaging method!");
                return
            }
            if (!this.doctorEmail) {
                this.$swal("Please enter the email ID!");
                return;
            }
            if (!this.doctorPincode) {
                this.$swal("Please enter the PinCode!");
                return;
            }
            if (!this.validEmail(this.doctorEmail)) {
                return false;
            }
            if (selectedPracticeIds.length === 0) {
                this.$swal("Please enter the practice area!");
                return;
            }
            this.click = true
            this.enableAllFields = true
            this.adminAction = true
            let updatePayload = {
                firstName:this.doctorFirstname,
                lastName:this.doctorLastName,
                age:this.doctorAge,
                email: this.doctorEmail,
                gender:this.doctorGender,
                mobile:this.doctorMobile,
                pincode:this.doctorPincode,
                registerNo: this.doctorRegNo,
                educational: this.doctorEducation,
                information: this.doctorInformation,
                practiceId: selectedPracticeIds,
                communication: this.doctorCommunicaton,
                updated_by:'doctor',
                status: {
                    'id': 5
                }
            };
            await axios
                .put(`${BASE_API_URL}/hcps/${this.userPayload.id}/update`, updatePayload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response?.data) {
              Swal.fire({
              title: "Profile updated successfully",
              // icon:'question',
              confirmButtonColor: "#7066e0",
              confirmButtonText: "OK",
              }).then((result) => {
            if (result.isConfirmed) {
            // const query = this.$route.query;
            window.scrollTo(0, 0);
            this.$router.push('/doctor/index')
             }
            });
                        
                    }
                })
                .catch(function (error) {
                    console.log(error?.response);
                });
           
        },
        async ReUpdate() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            const selectedPracticeIds = [];
            this.Option.forEach(item => {
                if (item.isActive) {
                    selectedPracticeIds.push(item);
                }
            });
            this.click = true
            this.enableAllFields = true
            this.adminAction = true
            let reupdatePayload = {
                firstName:this.doctorFirstname,
                lastName:this.doctorLastName,
                age:this.doctorAge,
                email: this.doctorEmail,
                gender:this.doctorGender,
                mobile:this.doctorMobile,
                pincode:this.doctorPincode,
                registerNo: this.doctorRegNo,
                educational: this.doctorEducation,
                information: this.doctorInformation,
                practiceId: selectedPracticeIds,
                updated_by:'doctor'
            };
            await axios
                .put(`${BASE_API_URL}/hcps/${this.userPayload.id}/update`, reupdatePayload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response?.data) {
                        Swal.fire({
                        title: "Profile saved successfully",
                        confirmButtonColor: "#7066e0",
                        confirmButtonText: "OK",
                        }).then((result) => {
                    if (result.isConfirmed) {
                        window.scrollTo(0, 0);
                    this.$router.push('/doctor/index')
                        }
                    });
                    }
                })
                .catch(function (error) {
                    console.log(error?.response);
                });
        },
        validEmail(inputtxt) {
            var email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            var consecutiveSpecialChar = /[\W]{2}/;

            if (inputtxt.match(email) && !consecutiveSpecialChar.test(inputtxt)) {
                return true;
            } else {
                this.$swal("Please enter the correct email ID!");
                return false;
            }
        },
        async getCommunication() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            await axios
                .get(`${BASE_API_URL}/communication`)
                .then((response) => {
                    if (response?.data) {
                        this.Communication = response.data
                    }
                })
        },
      
    }
};
</script>
<style>
.practiceBox{
    cursor: pointer;
}
.star {
    color: red;
    font-size: 20px;
}
.redborder {
border: 2px solid red !important;
}
.hide-cursor {
    cursor: not-allowed;
}

.form-control.cursor {
    cursor: not-allowed;
}

.UpdateTextAlignment {
    text-align: left;
}

.primarystyle {
    margin-top: 30px;
}


@media screen and (max-width:991px) {
    .mobileStyleDoctor {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 6rem;
    }
    .aboutMeMobile {
        margin-left: 20px;
        /* margin-bottom: 10px; */
        margin-top: 1rem;
        color: #000;
        font-size: 21px;
        font-weight: 550;
    }

    .proflemobile {
        margin-top: 15px;
    }
}

@media screen and (max-width:767px) {
    .mobileDisplayCommunication {
        display: block;
    }

    .primarystyle {
        margin-top: 0px;
    }
}

</style>